import {Action, Selector, State, StateContext} from '@ngxs/store';
import {GeographyApiService} from '@matchsource/api/geography';
import orderBy from 'lodash-es/orderBy';
import {findArray} from '@matchsource/utils';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {GetCountries} from './countries.action';
import {compose} from '@ngxs/store/operators';
import {
  addOrReplaceEntities,
  loadingEntities,
  EntitiesStateModel,
  loadedEntities,
  selectEntities,
  defaultEntitiesState,
  setError,
} from '@matchsource/store/core';
import {of} from 'rxjs';
import {CountryModel, isUsCountry, COUNT_OTHER, COUNTRY_US} from '@matchsource/models/country';

export type CountriesStateModel = EntitiesStateModel<CountryModel>;

@State<CountriesStateModel>({
  name: 'countries',
  defaults: defaultEntitiesState(),
})
@Injectable()
export class CountriesState {
  @Selector([CountriesState])
  static entities(state: CountriesStateModel) {
    return selectEntities<CountryModel>(state);
  }

  @Selector([CountriesState])
  static map(state: CountriesStateModel) {
    return state.entities;
  }

  @Selector([CountriesState])
  static loading(state: CountriesStateModel) {
    return state.loading;
  }

  @Selector([CountriesState])
  static countryByCode(state: CountriesStateModel) {
    return (code: string) => state.entities[code];
  }

  constructor(private readonly geographyApi: GeographyApiService) {}

  @Action(GetCountries)
  getAll(ctx: StateContext<CountriesStateModel>) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.geographyApi.getCountries().pipe(
      catchError(error => {
        ctx.setState(setError(error));
        return of([]);
      }),
      map(countries => {
        const usCountry = findArray<CountryModel>(countries, country => isUsCountry(country.code));
        const otherCountry = findArray<CountryModel>(countries, country => country.code === COUNT_OTHER);

        return [
          ...usCountry,
          ...orderBy(
            countries.filter(country => country.code !== COUNTRY_US && country.code !== COUNT_OTHER),
            'name'
          ),
          ...otherCountry,
        ];
      }),
      tap(countries =>
        ctx.setState(compose(addOrReplaceEntities<CountryModel>('code', countries), loadedEntities(true)))
      ),
      finalize(() => ctx.setState(loadingEntities(false)))
    );
  }
}
